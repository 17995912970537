import {createStyles} from "@mantine/core";

export const useLayoutStyles = createStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            display: 'block'
        },
    },
    leftBar: {
        backgroundColor: '#d53f3f',
        width: '576px',
        padding: '64px 64px 40px 64px',
        height: '100%',

        [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
            flex: '0 0 40%'
        },

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            width: '100%',
            padding: '40px 20px 40px 20px',
            height: 'unset'
        },
    },
    rightBar: {
        padding: '64px 80px 100px',
        paddingTop: '164px',
        flex: 1,
        overflowX: 'hidden',

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            width: '100%',
            padding: '40px 20px 40px 20px',
            flex: 0,
        },

        [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
            paddingLeft: '40px',
            paddingRight: '40px',
        },
    },
    rightBarSmallPaddings: {
        paddingTop: '34px',

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            width: '100%',
        },
    },
    logo: {
        height: '60px'
    },

    information: {
        marginTop: '55px',
        color: '#fff'
    },

    informationText: {
        fontSize: '20px',
        color: '#fff',
        fontWeight: 700,

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            fontSize: '16px'
        },
    },

    label: {
        fontSize: '14px',
        fontWeight: 500,
        marginTop: '2rem',
        color: '#fff',
    },

    black: {
        color: theme.colors.dark[9],
    },

    small: {
        fontSize: '16px',
    }
}))

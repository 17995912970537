import {Divider, Text, Title} from "@mantine/core";
import {useSectionStyles} from "./Section.styles";
import React from "react";

interface Props {
    title: string,
    children?: React.ReactNode
}


export default function Section(props: Props) {
    const { classes } = useSectionStyles();

    return (
        <div className={classes.container}>
            <Title order={2} className={classes.title}>{props.title}</Title>
            <Divider className={classes.divider}></Divider>

            {props.children}
        </div>
    )
}
import {Order} from "../../api/Order/Response/Order";
import {useLayoutStyles} from "../Layout/Layout.styles";
import {useTranslation} from "react-i18next";
import {Box, Text} from "@mantine/core";
import classNames from "classnames";
import React from "react";

export const ConsultantInfo = ({order, children}: {order: Order, children?: React.ReactNode}) => {
    const {classes} = useLayoutStyles();
    const {t} = useTranslation();

    if (order.assignedEmployee.id === 0) {
        return null;
    }

    return (
        <Box mb={10}>
            <Text className={classNames(classes.label, classes.black)}>{t('sidebar.supervisor')}</Text>
            <Text className={classNames(classes.informationText, classes.black, classes.small)}>{order.assignedEmployee.fullName.name} {order.assignedEmployee.fullName.surname}</Text>
            <Text className={classNames(classes.informationText, classes.black, classes.small)}>{order.assignedEmployee.email}</Text>
            <Text className={classNames(classes.informationText, classes.black, classes.small)}>{order.assignedEmployee.phone}</Text>
            <Text className={classNames(classes.informationText, classes.black, classes.small)}>ul. Wirażowa 124 A, 02-145 Warszawa</Text>

            {children}
        </Box>
    )
}

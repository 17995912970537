import {createStyles} from "@mantine/core";

export const useSectionStyles = createStyles((theme) => ({
    container: {
        marginTop: '16px'
    },

    title: {
        textTransform: 'uppercase',
        fontSize: '15px',
        marginBottom: 5,
        color: '#1a1f36',
    },

    divider: {
        borderTopWidth: 1,
        borderColor: '#e3e8ee',
        marginTop: '1rem',
    }
}));
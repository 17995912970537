import {Elements} from "@stripe/react-stripe-js";
import {stripePromise} from "../../index";
import React from "react";
import {useCountryStore} from "../../store/CountryStore";
import {useTranslation} from "react-i18next";
import {StripeElementLocale} from "@stripe/stripe-js";

interface Props {
    clientSecret: string;
    children: React.ReactNode
}

export default function Stripe(props: Props) {
    const {country} = useCountryStore();
    const {i18n} = useTranslation();

    return (
        <Elements stripe={stripePromise(country.code)}
                  options={{clientSecret: props.clientSecret, locale: i18n.language as StripeElementLocale}}>
            {props.children}
        </Elements>
    )
}

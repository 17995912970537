import {useUserStore} from "../../store/UserStore";
import {useHttp} from "../../lib/http";
import Client from "./Client";

export const useClientApi = () => {
    const token = useUserStore().token;
    const http = useHttp();

    const loadClient = () => {
        return http.get<Client>(`/purchaser/api/client/${token}`).then(r => r.data);
    }

    return {
        loadClient
    }
}
import {Button, ScrollArea, Table, Text} from "@mantine/core";
import {dinero} from "../../lib/dinero";
import {useInvoicesStyles} from "./Invoices.styles";
import {useTranslation} from "react-i18next";
import Section from "../Section/Section";
import {useInvoiceStore} from "../../store/InvoiceStore";
import Dinero from "dinero.js";
import {useInvoiceApi} from "../../api/Invoice/useInvoiceApi";
import {useState} from "react";
import {promises} from "dns";

export default function Invoices() {
    const {invoices, currencyBalances} = useInvoiceStore();
    const {classes} = useInvoicesStyles();
    const {t} = useTranslation();
    const {downloadInvoice, downloadInvoiceDuplicate} = useInvoiceApi();
    const [loading, setLoading] = useState(false);

    if (invoices.length === 0) {
        return <></>
    }

    const load = async (action: Promise<any>) => {
        setLoading(true);
        await action;
        setLoading(false);
    }

    return (
        <>
            <Section title={t('invoices.totalAmountToPaid')}>
                <Table className={classes.unpaidTable}>
                    <thead>
                        <tr>
                            <th><Text>{t('invoices.sum')}</Text></th>
                            <th><Text>{t('invoices.currency')}</Text></th>
                        </tr>
                    </thead>
                    <tbody>
                    {currencyBalances.map((currencyBalance) =>
                        <tr>
                            <td>{dinero(currencyBalance.gross).toUnit()}</td>
                            <td>{currencyBalance.currency}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Section>
            <Section title={t('invoices.unpaidInvoices')}>
                <ScrollArea>
                    <Table>
                        <tbody>
                        { invoices.map((invoice, index) =>
                            <tr key={index}>
                                <td>
                                    <Text size={"sm"} weight={700}>{invoice.saleDate.formatted}</Text>
                                    <Text>{t('invoices.number')} {invoice.invoiceNumber}</Text>
                                </td>
                                <td>
                                    <Text size={"sm"} weight={700}>{t('invoices.dueDate')}</Text>
                                    <Text>{invoice.paymentToDate.formatted}</Text>
                                </td>
                                <td>
                                    <Text size={"sm"} weight={700}>{t('invoices.total')}</Text>
                                    <Text>{dinero(invoice.costWithTax).toFormat()}</Text>
                                </td>
                                <td width="30%">
                                    <Text size={"sm"} weight={700}>{t('invoices.unpaid')}</Text>
                                    <Text className={classes.unpaid}>{dinero(invoice.balanceGross).toFormat()}</Text>
                                </td>
                                <td width="30%">
                                    <Button size={"xs"} loading={loading} onClick={() => load(downloadInvoice(invoice.id))}>{t('invoices.download')}</Button>
                                    <br/>
                                    <Button size={"xs"} mt={10} loading={loading} onClick={() => load(downloadInvoiceDuplicate(invoice.id))}>{t('invoices.downloadDuplicate')}</Button>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </ScrollArea>
            </Section>
        </>
    )
}
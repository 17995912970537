import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import en from "./en.json";
import pl from "./pl.json";
import es from "./es.json";
import fr from "./fr.json";
import de from "./de.json";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: en
            },
            pl: {
                translation: pl,
            },
            fr: {
                translation: fr,
            },
            es: {
                translation: es,
            },
            de: {
                translation: de,
            }
        },
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            order: ['path']
        }
    })

export default i18n;

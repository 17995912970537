import {createStyles} from "@mantine/core";

export const useOrderPageStyles = createStyles((theme) => ({
    pdfWrapper: {
        height: '100%',
        maxWidth: '100%',
    },
    pdfWrapperFix: {
        height: '0px',
        width: '100%',
    },
    paymentCard: {
        cursor: 'pointer',
        border: `2px solid ${theme.white}`,
        borderRadius: theme.radius.sm
    },
    paymentCardActive: {
        border: `2px solid ${theme.colors.blue[5]}`,
        color: theme.colors.blue[5]
    }
}));
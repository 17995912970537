import ApiMoney from "../Common/ApiMoney";
import ApiDate from "../Common/ApiDate";

export interface Subscription {
    id: string
    status: SubscriptionStatus
    items: SubscriptionItem[]
    todayItems: SubscriptionItem[]
}

export const SubscriptionStatus = {
    ACTIVE: "active",
    NO_PAYMENT_METHOD: "no_payment_method",
    STARTING: "starting",
    UNPAID: "unpaid",
};

export interface SubscriptionItem {
    name: string;
    price: ApiMoney;
    priceGross: ApiMoney;
    recurring: boolean;
    from?: ApiDate;
    to?: ApiDate;
}

type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];

import {useUserStore} from "../../store/UserStore";
import {useHttp} from "../../lib/http";
import {Collection} from "../Common/Collection";
import Invoice from "./Invoice";
import CurrencyBalance from "./CurrencyBalance";
import DownloadInvoiceResponse from "./Response/DownloadInvoiceResponse";
import {showNotification} from "@mantine/notifications";
import {useTranslation} from "react-i18next";
import downloadBase64AsPdf from "../../lib/downloadBase64AsPdf";
import DownloadInvoiceDuplicateResponse from "./Response/DownloadInvoiceDuplicateResponse";

export const useInvoiceApi = () => {
    const token = useUserStore().token;
    const http = useHttp();
    const {t} = useTranslation();

    const loadInvoices = async (): Promise<Collection<Invoice>> => {
        return await http.get(`/purchaser/api/client/${token}/invoices`).then(r => r.data);
    }

    const loadCurrencyBalances = async (): Promise<Collection<CurrencyBalance>> => {
        return await http.get(`/purchaser/api/client/${token}/invoices/balance`).then(r => r.data);
    }

    const downloadInvoice = async (invoiceBalanceId: string): Promise<void> => {
        try {
            const response = await http.get<DownloadInvoiceResponse>(`/purchaser/api/client/${token}/invoices/${invoiceBalanceId}/document`);

            if (response.status === 200) {
                downloadBase64AsPdf(`Invoice_${response.data.number}`, response.data.content);
                return;
            }

            showNotification({
                title: t('errorOccurred'),
                message: t('invoices.downloadError'),
                color: 'red',
                autoClose: false,
            });
        } catch (e) {
            showNotification({
                title: t('errorOccurred'),
                message: t('invoices.downloadError'),
                color: 'red',
                autoClose: false,
            });
        }
    }

    const downloadInvoiceDuplicate = async (invoiceBalanceId: string): Promise<void> => {
        try {
            const response = await http.post<DownloadInvoiceDuplicateResponse>(`/purchaser/api/client/${token}/invoices/${invoiceBalanceId}/document/duplicate`);

            if (response.status === 200) {
                if (response.data.code === 'success') {
                    downloadBase64AsPdf(`Invoice_Duplicate_${response.data.number}`, response.data.content);
                    return;
                }

                showNotification({
                    title: t('information'),
                    message: t(`invoices.duplicate.${response.data.code}`),
                    color: 'blue',
                    autoClose: false,
                });

                return;
            }

            showNotification({
                title: t('errorOccurred'),
                message: t('invoices.duplicateDownloadError'),
                color: 'red',
                autoClose: false,
            });
        } catch (e) {
            showNotification({
                title: t('information'),
                message: t('invoices.duplicateDownloadError'),
                color: 'red',
                autoClose: false,
            });
        }
    }

    return {
        loadInvoices,
        loadCurrencyBalances,
        downloadInvoice,
        downloadInvoiceDuplicate
    }
}
import {createStyles} from "@mantine/core";

export const useFullscreenLoaderStyles = createStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
    }
}))
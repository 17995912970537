import React from "react";
import {Global, MantineProvider} from "@mantine/core";
import {NotificationsProvider} from "@mantine/notifications";

interface Props {
    children: React.ReactNode
}

export default function MantineDefault(props: Props) {
    return (
        <MantineProvider theme={{
            fontFamily: 'Noto Sans, sans-serif',
            headings: { fontFamily: 'Noto Sans, sans-serif' },
            components: {
                Title: {
                    styles: {
                        root: {
                            color: '#181929'
                        }
                    }
                },
                Text: {
                    styles: {
                        root: {
                            color: '#181929'
                        }
                    }
                }
            }
        }}>
            <Global styles={() => ({
                '*, *::before, *::after': {
                    boxSizing: 'border-box',
                },

                'html, body, #root': {
                    height: '100%',
                }
            })}/>

            <NotificationsProvider>
                {props.children}
            </NotificationsProvider>
        </MantineProvider>
    )
}
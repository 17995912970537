import {createStyles} from "@mantine/core";

export const useInvoicesStyles = createStyles((theme) => ({
    unpaid: {
        color: '#d53f3f',
        fontWeight: 900
    },
    unpaidTable: {
        width: 'fit-content',
        marginTop: '1rem'
    }
}));
import {Order} from "../../api/Order/Response/Order";
import {useOrderPageStyles} from "../../pages/order.styles";
import {useEffect, useRef, useState} from "react";
import {useMantineTheme} from "@mantine/core";
import {Document, Page, pdfjs} from "react-pdf/dist/esm/entry.webpack";
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader";

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const pdfA4PageRatio = 1.414;

export const OrderDocument = ({order}: {order: Order}) => {
    const {classes} = useOrderPageStyles();
    const [initialWidth, setInitialWidth] = useState<number | undefined>(undefined);
    const [initialHeight, setInitialHeight] = useState<number | undefined>(undefined);
    const pdfWrapper = useRef<HTMLDivElement | null>(null);
    const pdfWrapperFix = useRef<HTMLDivElement | null>(null);
    const theme = useMantineTheme();

    const [numPages, setNumPages] = useState<null|number>(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }: {numPages: number}) => {
        setNumPages(numPages);
    }

    const setPdfSize = () => {
        if (pdfWrapper && pdfWrapper.current && pdfWrapperFix && pdfWrapperFix.current) {
            const height = pdfWrapper.current.getBoundingClientRect().height;
            const width = pdfWrapperFix.current.getBoundingClientRect().width;

            if ((height / pdfA4PageRatio < window.innerWidth && width * pdfA4PageRatio < window.innerHeight) || window.innerWidth <= theme.breakpoints.md) {
                setInitialWidth(width);
                setInitialHeight(undefined);
            } else {
                const pdfWrapper = document.getElementById('pdfWrapper');

                if (pdfWrapper) {
                    if (pdfWrapper.getBoundingClientRect().width > 1200) {
                        setInitialWidth(1000);
                        setInitialHeight(height);

                        return;
                    }

                    setInitialWidth(pdfWrapper.getBoundingClientRect().width);
                    setInitialHeight(undefined);
                } else {
                    setInitialWidth(undefined)
                    setInitialHeight(height);
                }
            }

        }
    };

    useEffect(() => {
        window.addEventListener('resize', setPdfSize);
        setPdfSize();
        return () => {
            window.removeEventListener('resize', setPdfSize);
        };
    }, []);


    return (
        <>
            <div id={'pdfWrapperFix'} ref={pdfWrapperFix} className={classes.pdfWrapperFix}></div>
            <div id={'pdfWrapper'} ref={pdfWrapper} className={classes.pdfWrapper}>
                <Document file={`data:application/pdf;base64,${order.content}`} loading={<FullscreenLoader/>} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            height={initialHeight}
                            width={initialWidth}
                        />
                    ))}
                </Document>
            </div>
        </>
    )
}

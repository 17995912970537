import {useHttp} from "../../lib/http";
import {Order} from "./Response/Order";
import {OrderSignRequest} from "./Request/OrderSignRequest";
import {Subscription} from "../Susbscription/Subscription";
import SetupIntent from "../Susbscription/SetupIntent";

export const useOrderApi = () => {
    const http = useHttp();

    return {
        async order(token: string) {
            return http.get<Order>(`/purchaser/api/client/order/${token}`).then((r) => r.data);
        },

        async sign(token: string, request: OrderSignRequest) {
            return await http.post<SignOrderResponse>(`/purchaser/api/client/order/${token}/sign`, request).then((r) => r.data);
        },

        async subscription(token: string, subscriptionId: string) {
            return await http.post<Subscription>(`/purchaser/api/client/order/${token}/subscription/${subscriptionId}`).then((r) => r.data);
        }
    }
}

export interface SignOrderResponse {
    subscription?: Subscription;
    intent?: SetupIntent;
}
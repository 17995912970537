import {useFullscreenLoaderStyles} from "./FullscreenLoader.styles";
import {Loader} from "@mantine/core";

export default function FullscreenLoader() {
    const {classes} = useFullscreenLoaderStyles();

    return (
        <div className={classes.container}>
            <Loader size={"lg"}></Loader>
        </div>
    )
}
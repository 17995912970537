import create from "zustand";
import {CountryConfig, loadConfig} from "../config/countries";

type State = {
    country: CountryConfig;
    changeCountry: (code: string) => void;
}

export const useCountryStore = create<State>(setState => ({
    country: null as unknown as CountryConfig,
    changeCountry: (code: string) => setState({country: loadConfig(code)})
}))
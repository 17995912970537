import {useOrderPageStyles} from "../../pages/order.styles";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Card,
    Checkbox,
    Group,
    LoadingOverlay,
    Stack,
    Text,
    TextInput,
    Notification,
    Title,
    useMantineTheme,
    Anchor,
    Divider,
} from "@mantine/core";
import {SignOrderResponse, useOrderApi} from "../../api/Order/useOrderApi";
import {useState} from "react";
import * as Yup from "yup";
import {useForm, yupResolver} from "@mantine/form";
import classNames from "classnames";
import {Order} from "../../api/Order/Response/Order";
import downloadBase64AsPdf, {downloadFromUrl} from "../../lib/downloadBase64AsPdf";
import {useCountryStore} from "../../store/CountryStore";

interface Form {
    paymentOperator: 'sap' | 'stripe',
    name: string,
    position: string,
    contactEmail: string,
    invoiceEmail: string,
    authorizedPersonAgreement: boolean,
    rulesAgreement: boolean,
    gowhistleRulesAgreement: boolean,
}

export const SignOrderForm = ({token, order, handleSigned}: {token: string, order: Order, handleSigned: (response: SignOrderResponse, operator: 'sap' | 'stripe') => void,}) => {
    const {classes} = useOrderPageStyles();
    const {t} = useTranslation();
    const theme = useMantineTheme();
    const {sign} = useOrderApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const {country} = useCountryStore();

    const formSchema = Yup.object().shape({
        paymentOperator: Yup.string().required(t('order.fieldRequired')),
        name: Yup.string().required(t('order.fieldRequired')),
        position: Yup.string().required(t('order.fieldRequired')),
        contactEmail: Yup.string().required(t('order.fieldRequired')).email(t('order.emailInvalid')),
        invoiceEmail: Yup.string().required(t('order.fieldRequired')).email(t('order.emailInvalid')),
        authorizedPersonAgreement: Yup.boolean().isTrue(t('order.agreementRequired')),
        rulesAgreement: order.hasGoworkService ? Yup.boolean().isTrue(t('order.agreementRequired')) : Yup.boolean(),
        gowhistleRulesAgreement: order.hasGowhistleService ? Yup.boolean().isTrue(t('order.agreementRequired')) : Yup.boolean()
    });

    const form = useForm<Form>({
        initialValues: {
            name: '',
            position: '',
            contactEmail: '',
            invoiceEmail: '',
            paymentOperator: order.paymentOperator === null ? country.defaultPaymentMethod : order.paymentOperator,
            rulesAgreement: false,
            gowhistleRulesAgreement: false,
            authorizedPersonAgreement: false,
        },
        validate: yupResolver(formSchema),
    });

    const handleSubmit = async (values: Form) => {
        setLoading(true);
        try {
            handleSigned(await sign(token, values), values.paymentOperator);
        } catch (e) {
            setError(true);
        }
        setLoading(false);
    }

    return (
        <Box sx={{position: 'relative'}}>
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <Stack>
                    <Divider/>
                    <LoadingOverlay visible={loading} overlayColor={theme.colors.gray[1]}/>
                    { error && <Notification title={t('order.errorTitle')} color={'red'}>{t('order.errorDesc')}</Notification> }

                    <Title size={'medium'}>{t('order.ordererData')}</Title>

                    <TextInput label={t('order.fullName')} {...form.getInputProps('name')}></TextInput>
                    <TextInput label={t('order.position')} {...form.getInputProps('position')}></TextInput>
                    <TextInput label={t('order.collaborationEmail')} {...form.getInputProps('contactEmail')}></TextInput>
                    <TextInput label={t('order.invoiceEmail')} {...form.getInputProps('invoiceEmail')}></TextInput>

                    { order.paymentOperator === null &&
                        <>
                            <Text size={"sm"} weight={500}>{t('order.paymentMethod')}</Text>
                            <Group>
                                {
                                    (!country.stripeDisabled) &&
                                    (order.allowedPaymentsMethods.includes('stripe')) &&
                                    <Card className={classNames(classes.paymentCard, {[classes.paymentCardActive]: form.values.paymentOperator === 'stripe'})} onClick={() => form.setFieldValue('paymentOperator', 'stripe')}>
                                        <Group>
                                            <Text>{t('order.card')}</Text>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path
                                                    d="M0 8v-2c0-1.104.896-2 2-2h20c1.104 0 2 .896 2 2v2h-24zm24 3v7c0 1.104-.896 2-2 2h-20c-1.104 0-2-.896-2-2v-7h24zm-15 5h-6v1h6v-1zm3-2h-9v1h9v-1zm9 0h-3v1h3v-1z"/>
                                            </svg>
                                        </Group>
                                    </Card>
                                }
                                <Card className={classNames(classes.paymentCard, {[classes.paymentCardActive]: form.values.paymentOperator === 'sap'})} onClick={() => form.setFieldValue('paymentOperator', 'sap')}>
                                    <Group>
                                        <Text>{t('order.transfer')}</Text>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path
                                                d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z"/>
                                        </svg>
                                    </Group>
                                </Card>
                            </Group>
                        </>
                    }

                    <Checkbox label={t('order.authorizedPersonAgreement', {company: order.payer.name})} {...form.getInputProps('authorizedPersonAgreement', { type: 'checkbox' })}/>

                    { order.hasGoworkService &&
                        <Checkbox label={
                            <>
                                {t('order.rulesAgreement')} {' '} <Anchor target={'_blank'} href={country.rulesUrl}>{t('order.showRules')}</Anchor>
                            </>
                        } {...form.getInputProps('rulesAgreement', { type: 'checkbox' })}/>
                    }

                    { order.hasGowhistleService &&
                        <Checkbox label={
                            <>
                                {t('order.gowhistleRulesAgreement')} {' '} <Anchor target={'_blank'} href="https://gowhistle.com/regulamin/ ">{t('order.showRules')}</Anchor>
                            </>
                        } {...form.getInputProps('gowhistleRulesAgreement', { type: 'checkbox' })}/>
                    }

                    <Button type={"submit"}>{t('order.signOrder')}</Button>
                    <Button type={"button"} color={'gray'} onClick={() => downloadBase64AsPdf(order.number.text, order.content || '')}>{t('order.downloadAsPdf')}</Button>

                    { country.code === 'de' &&
                        <Button type={"button"} color={'gray'} onClick={() => downloadFromUrl('Informationsbltt zum Premium-Profil', '/resources/infoblatt.pdf')}>Informationsbltt zum Premium-Profil Download</Button>
                    }
                </Stack>
            </form>
        </Box>
    )
}

import {useParams} from "react-router-dom";
import {SignOrderResponse, useOrderApi} from "../api/Order/useOrderApi";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Page404 from "./404";
import {
    Text,
    useMantineTheme,
    Overlay,
    Center, Title, Stack, Button, Anchor
} from "@mantine/core";
import Layout from "../components/Layout/Layout";
import {Order, OrderStatus} from "../api/Order/Response/Order";
import FullscreenLoader from "../components/FullscreenLoader/FullscreenLoader";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {useTranslation} from "react-i18next";
import {AxiosError} from "axios";
import {SignOrderForm} from "../components/Order/SignOrderForm";
import {OrderDocument} from "../components/Order/OrderDocument";
import {ConsultantInfo} from "../components/Order/ConsultantInfo";
import {Subscription} from "../api/Susbscription/Subscription";
import SetupPaymentForOrder from "../components/Order/SetupPaymentForOrder";
import SetupIntent from "../api/Susbscription/SetupIntent";
import {useCountryStore} from "../store/CountryStore";
import {GOWHISTLE_THANK_YOU} from "../config/countries";

const ErrorOverlay = ({text}: {text: string}) => {
    return (
        <Overlay opacity={1}>
            <Center sx={{height: '100%'}}><Text size={'lg'} color={'white'}>{text}</Text></Center>
        </Overlay>
    )
}

export default function OrderPage() {
    const {token} = useParams();
    const orderApi = useOrderApi();
    const {t} = useTranslation();
    const theme = useMantineTheme();
    const {country} = useCountryStore();

    const [initializing, setInitializing] = useState(false);
    const [expired, setExpired] = useState(false);
    const [order, setOrder] = useState<Order | null>(null);
    const [signed, setSigned] = useState(false);
    const [displayPaymentSetup, setDisplayPaymentSetup] = useState(false);
    const [subscription, setSubscription] = useState<Subscription|null>(null);
    const [setupIntent, setSetupIntent] = useState<SetupIntent|null>(null);
    const [paymentOperator, setPaymentOperator] = useState<'sap' | 'stripe'>(country.defaultPaymentMethod);

    const isCompleted = useMemo(() => order?.status?.id === OrderStatus.CONTRACT_ACTIVE_COOPERATION, [order]);

    const fetchData = async () => {
        setInitializing(true);

        try {
            const response = await orderApi.order(token || '');

            setOrder(response);
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                if (error.request.status === 410) {
                    setExpired(true);
                }
            }
        }

        setInitializing(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    if (signed && order) {
        return (
            <Overlay opacity={1}>
                <Center style={{ height: '100%' }}>
                    <Stack align={"center"}>
                        {paymentOperator === 'stripe' &&
                            <>
                                <Title size={'medium'} align={"center"} styles={{width: '50%'}}>{t('order.signedCard.title')}</Title>
                                <Text align={"center"} styles={{width: '50%'}}>{t('order.signedCard.info')}</Text>
                            </>
                        }
                        {paymentOperator === 'sap' &&
                            <>
                                <Title size={'medium'} align={"center"} styles={{width: '50%'}}>{t('order.signedTransfer.title')}</Title>
                                <Text align={"center"} styles={{width: '50%'}}>{t('order.signedCard.info')}</Text>
                            </>
                        }
                    </Stack>
                </Center>
            </Overlay>
        )
    }

    if (initializing) {
        return <FullscreenLoader/>;
    }

    if (expired) {
        return <ErrorOverlay text={t('order.expired')}/>
    }

    if (isCompleted) {
        return <ErrorOverlay text={t('order.signed')}/>
    }

    if (!token || !order) {
        return (
            <Page404/>
        )
    }

    const onSigned = (response: SignOrderResponse, paymentOperator: 'stripe' | 'sap') => {
        setPaymentOperator(paymentOperator);

        if (response.subscription && response.intent) {
            setDisplayPaymentSetup(true);
            setSubscription(response.subscription);
            setSetupIntent(response.intent)
            return;
        }

        if (order.hasGowhistleService) {
            setInitializing(true);
            window.location.href = GOWHISTLE_THANK_YOU;
            return;
        }

        setSigned(true);
    }

    const onCardAdded = () => {
        if (order.hasGowhistleService) {
            setInitializing(true);
            window.location.href = GOWHISTLE_THANK_YOU;
            return;
        }

        setSigned(true);
    }

    return (
        <Layout smallPaddings leftPanel={
            <>
                <ConsultantInfo order={order}>
                    <Text>{t('order.expireDate', {expireDate: order.expireDate.formatted})}</Text>
                </ConsultantInfo>

                {(!subscription && !displayPaymentSetup && !setupIntent) &&
                    <SignOrderForm token={token} order={order} handleSigned={(response, operator ) => onSigned(response, operator)}/>
                }
            </>
        } leftPanelColor={theme.colors.gray[1]}>
            {(!subscription && !displayPaymentSetup && !setupIntent) &&
                <OrderDocument order={order}/>
            }

            {(subscription && displayPaymentSetup && setupIntent) && (
                <SetupPaymentForOrder order={order} token={token} subscription={subscription} setupIntent={setupIntent} handleAdded={() => onCardAdded()}/>
            )}
        </Layout>
    )
}

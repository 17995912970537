import FullName from "../../Common/FullName";
import ApiDate from "../../Common/ApiDate";

export interface Order {
    content?: string;
    status: {
        id: OrderStatus,
        key: string,
    };
    assignedEmployee: Consultant;
    number: OrderNumber;
    expireDate: ApiDate,
    hasGowhistleService: boolean;
    hasGoworkService: boolean;
    payer: {
        name: string
    },
    company: Company,
    allowedPaymentsMethods: string[],
    paymentOperator: PaymentOperator|null,
}

export type PaymentOperator = 'sap' | 'stripe';
export interface Company {
    externalId: string,
    externalSlug?: string,
}

interface OrderNumber {
    num: number,
    text: string,
}

export interface Consultant {
    id: number;
    fullName: FullName;
    email: string;
    phone: string;
}

export enum OrderStatus {
    CONTRACT_ACTIVE_COOPERATION = 3,
}

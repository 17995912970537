import {createStyles} from "@mantine/core";

export const usePage404Styles = createStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column'
    },

    status: {
        fontSize: '160px',
    }
}))
import {Outlet, useParams} from "react-router-dom";
import {useCountryStore} from "../store/CountryStore";
import {useEffect} from "react";
import MantineDefault from "../components/MantineDefault";
import {isAvailable} from "../config/countries";
import Page404 from "./404";

export default function CountrySetup() {
    const {country: urlCountry} = useParams();
    const {country, changeCountry} = useCountryStore();

    useEffect(() => changeCountry(urlCountry || ''), [urlCountry]);

    return (
        <MantineDefault>
            {(country) &&
                <Outlet/>
            }

            {
                (!isAvailable(urlCountry || '')) &&
                <Page404/>
            }
        </MantineDefault>
    )
}
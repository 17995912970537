import {useUserStore} from "../store/UserStore";
import {useSubscriptionStore} from "../store/SubscriptionStore";
import {useSubscriptionsApi} from "../api/Susbscription/useSubscriptionsApi";
import React, {useEffect, useState} from "react";
import {useClientApi} from "../api/Client/useClientApi";
import {useInvoiceApi} from "../api/Invoice/useInvoiceApi";
import {useInvoiceStore} from "../store/InvoiceStore";
import {SubscriptionStatus} from "../api/Susbscription/Subscription";
import Page404 from "../pages/404";
import FullscreenLoader from "./FullscreenLoader/FullscreenLoader";

interface Props {
    children: React.ReactNode
}

export default function BasicDataLoader(props: Props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const {loadClient} = useClientApi();
    const {loadInvoices, loadCurrencyBalances} = useInvoiceApi();
    const {loadSubscriptions, loadSetupIntent} = useSubscriptionsApi();

    const {subscriptions, changeSubscriptions, changeSetupIntent} = useSubscriptionStore();
    const {changeInvoices, changeCurrencyBalances} = useInvoiceStore();
    const {token, changeClient} = useUserStore();

    const load = async () => {
        setLoading(true);

        try {
            const [apiSubscriptions, client, invoices, currencyBalances] = await Promise.all([
                loadSubscriptions(),
                loadClient(),
                loadInvoices(),
                loadCurrencyBalances()
            ])


            changeSubscriptions(apiSubscriptions.items);
            changeClient(client);
            changeInvoices(invoices.items);
            changeCurrencyBalances(currencyBalances.items);

            if (apiSubscriptions.items.length > 0) {
                for (const subscription of apiSubscriptions.items) {
                    if ([SubscriptionStatus.NO_PAYMENT_METHOD, SubscriptionStatus.UNPAID].includes(subscription.status)) {
                        changeSetupIntent(await loadSetupIntent(subscription.id));
                        break;
                    }
                }
            }
        } catch (e) {
            setError(true)
        }

        setLoading(false);
    }

    useEffect(() => {
        load().then()
    }, [token]);

    return (
        <>
            {error && <Page404/>}
            {(!error && !loading) && <>{props.children}</>}
            {(loading && !error) && <FullscreenLoader/>}
        </>
    )
}

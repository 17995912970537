import {Subscription} from "../../api/Susbscription/Subscription";
import {useSubscriptionStore} from "../../store/SubscriptionStore";
import Subscriptions from "../Subscription/Subscriptions";
import {useEffect, useState} from "react";
import SetupIntentModel from "../../api/Susbscription/SetupIntent";
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader";
import Stripe from "../SetupIntent/Stripe";
import SetupIntent from "../SetupIntent/SetupIntent";
import {useOrderApi} from "../../api/Order/useOrderApi";
import {Order} from "../../api/Order/Response/Order";
import {Alert, Text, Title} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {IconAlertCircle} from "@tabler/icons-react";

interface Props {
    subscription: Subscription;
    setupIntent: SetupIntentModel;
    token: string;
    handleAdded: () => void,
    order: Order,
}

export default function SetupPaymentForOrder(props: Props) {
    const [loading, setLoading] = useState(true);
    const subscriptions = useSubscriptionStore();
    const orderApi = useOrderApi();
    const {t} = useTranslation();

    const fetchSubscription = async () => {
        return [await orderApi.subscription(props.token, props.subscription.id)];
    }

    useEffect(() => {
        setLoading(true);
        subscriptions.changeSubscriptions([props.subscription]);
        subscriptions.changeSetupIntent(props.setupIntent);
        setLoading(false);
    }, []);

    if (loading || subscriptions.subscriptions.length === 0 || subscriptions.setupIntent === null) {
        return <FullscreenLoader/>
    }

    return (
        <div>
            <Title size={'large'}>{t('order.cardSetup.title')}</Title>
            <Text size={'md'}>{t('order.cardSetup.info')}</Text>

            <Subscriptions/>
            <Subscriptions today/>

            <Alert icon={<IconAlertCircle size="1rem" />} title="Info">
                <>{t('fillCard.paymentProRataBasis')}</>
                <br/>
                <>{t('fillCard.paymentDate')}</>
            </Alert>

            <Stripe clientSecret={props.setupIntent.clientSecret}>
                <SetupIntent subscriptionId={props.setupIntent.id}
                             clientSecret={props.setupIntent.clientSecret}
                             loadSubscriptions={fetchSubscription}
                             order={props.order}
                             handleAdded={() => props.handleAdded()}
                />
            </Stripe>
        </div>
    )
}

import Section from "../Section/Section";
import Stripe from "./Stripe";
import SetupIntent from "./SetupIntent";
import {useSubscriptionStore} from "../../store/SubscriptionStore";
import {useTranslation} from "react-i18next";
import {List} from "@mantine/core";

export default function FillCardData() {
    const {t} = useTranslation();
    const {setupIntent} = useSubscriptionStore();

    if (setupIntent === null) {
        return <></>
    }

    return (
        <Section title={t('fillCard.noPaymentMethod')}>
            <Stripe clientSecret={setupIntent.clientSecret}>
                <SetupIntent subscriptionId={setupIntent.id}
                             clientSecret={setupIntent.clientSecret}/>
            </Stripe>
        </Section>
    )
}

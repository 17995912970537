import {usePage404Styles} from "./404.styles";
import {Text, Title} from "@mantine/core";

export default function Page404() {
    const { classes } = usePage404Styles();

    return (
        <div className={classes.container}>
            <Title className={classes.status} order={1}>404</Title>
            <Text weight={700} size={"xl"}>Oooops!</Text>
            <Text weight={700} size={"xl"}>That page doesn't exist or is unavailable.</Text>
        </div>
    )
}
import create from "zustand";
import Invoice from "../api/Invoice/Invoice";
import CurrencyBalance from "../api/Invoice/CurrencyBalance";

type State = {
    invoices: Invoice[],
    currencyBalances: CurrencyBalance[],
    changeInvoices: (invoices: Invoice[]) => void,
    changeCurrencyBalances: (currencyBalances: CurrencyBalance[]) => void,
    hasInvoices: () => boolean
}

export const useInvoiceStore = create<State>((set, get) => ({
    invoices: [],
    currencyBalances: [],
    changeInvoices: (invoices: Invoice[]) => set({invoices}),
    changeCurrencyBalances: (currencyBalances: CurrencyBalance[]) => set({currencyBalances}),
    hasInvoices: () => get().invoices.length > 0
}))
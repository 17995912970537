import create from "zustand";
import {Subscription, SubscriptionStatus} from "../api/Susbscription/Subscription";
import {useSubscriptionsApi} from "../api/Susbscription/useSubscriptionsApi";
import SetupIntent from "../api/Susbscription/SetupIntent";

type State = {
    subscriptions: Subscription[],
    setupIntent: SetupIntent|null
    changeSubscriptions: (subscriptions: Subscription[]) => void,
    changeSetupIntent: (intent: SetupIntent|null) => void,
    containsWithoutPaymentMethod: () => boolean,
};

export const useSubscriptionStore = create<State>((setState, getState) => ({
    subscriptions: [],
    setupIntent: null,
    changeSubscriptions: (subscriptions: Subscription[]) => setState({subscriptions: subscriptions}),
    changeSetupIntent: (intent: SetupIntent|null) => setState({setupIntent: intent}),
    containsWithoutPaymentMethod: () => {
        return getState().subscriptions.filter((s) => [SubscriptionStatus.NO_PAYMENT_METHOD,SubscriptionStatus.UNPAID].includes(s.status)).length !== 0;
    }
}));


import Layout from "../components/Layout/Layout";
import Subscriptions from "../components/Subscription/Subscriptions";
import Invoices from "../components/Invoices/Invoices";
import FillCardData from "../components/SetupIntent/FillCardData";
import BasicDataLoader from "../components/BasicDataLoader";
import {Alert} from "@mantine/core";
import {useTranslation} from "react-i18next";
import { IconAlertCircle } from '@tabler/icons-react';

export default function Index() {
    const {t} = useTranslation();
    return (
        <>
            <BasicDataLoader>
                <Layout>
                    <Subscriptions/>
                    <Subscriptions today/>

                    <Alert icon={<IconAlertCircle size="1rem" />} title="Info">
                        <>{t('fillCard.paymentProRataBasis')}</>
                        <br/>
                        <>{t('fillCard.paymentDate')}</>
                    </Alert>

                    <FillCardData/>
                    <Invoices/>
                </Layout>
            </BasicDataLoader>
        </>
    )
}

import create from "zustand";
import Client from "../api/Client/Client";

type State = {
    token: string|null,
    changeToken: (token: string) => void,
    client: Client,
    changeClient: (client: Client) => void,
}

export const useUserStore = create<State>(setState => ({
    token: null,
    client: null as unknown as Client,
    changeToken: (token: string) => setState({token}),
    changeClient: (client: Client) => setState({client})
}))
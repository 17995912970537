import {Badge, Table, Text} from "@mantine/core";
import {dinero} from "../../lib/dinero";
import {useTranslation} from "react-i18next";
import Section from "../Section/Section";
import {useSubscriptionStore} from "../../store/SubscriptionStore";
import {SubscriptionItem} from "../../api/Susbscription/Subscription";

interface Props {
    today?: boolean
}

function ItemRow(index: number, item: SubscriptionItem, displayType: boolean) {
    const {t} = useTranslation();

    return <tr key={index}>
        <td width="40%">
            <Text>{t(`subscriptions.name.${item.name}`)}</Text>
            { item.from && item.to &&
                <Text>{item.from.formatted} - {item.to.formatted}</Text> }
        </td>
        <td width="30%">
            <Text>{dinero(item.price).toFormat()}</Text>
        </td>
        <td width="30%">
            <Text>{dinero(item.priceGross).toFormat()}</Text>
        </td>
        { displayType ?
        <td width="30%">
            { item.recurring &&
                <Badge color={'green'} variant={'filled'}>{t('subscriptions.recurring')}</Badge>
            }

            {
                !item.recurring &&
                <Badge variant={'filled'}>{t('subscriptions.oneTimePayment')}</Badge>
            }
        </td> : <td width="30%"></td> }
    </tr>;
}

export default function Subscriptions(props: Props) {
    const {t} = useTranslation();
    const {subscriptions} = useSubscriptionStore();
    const items: SubscriptionItem[] = [];

    for (const subscription of subscriptions) {
        items.push(...(props.today ? subscription.todayItems : subscription.items.filter((item) => item.recurring)))
    }

    if (items.length === 0) {
        return <></>
    }

    const displayType = !props.today;

    return (
        <Section title={props.today ? t('subscriptions.todayItems') : t('subscriptions.services')}>
            <Table>
                <thead>
                <tr>
                    <th></th>
                    <th>{t('net')}</th>
                    <th>{t('gross')}</th>
                    { displayType ? <th>Type</th> : <td></td> }
                </tr>
                </thead>
                <tbody>
                { items.map((item, index) => ItemRow(index, item, displayType)) }
                </tbody>
            </Table>
        </Section>
    )
}

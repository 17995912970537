import React from "react";
import {Grid, Image, Text} from "@mantine/core";
import {useLayoutStyles} from "./Layout.styles";
import Logo from "./Logo";
import {useCountryStore} from "../../store/CountryStore";
import {useUserStore} from "../../store/UserStore";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

interface Props {
    children?: React.ReactNode;
    leftPanel?: React.ReactNode;
    smallPaddings?: boolean;
    leftPanelColor?: string;
}

export default function Layout(props: Props) {
    const {t} = useTranslation();

    const {classes} = useLayoutStyles();
    const {country} = useCountryStore();
    const {client} = useUserStore();

    return (
        <div className={classes.container}>
            <div className={classes.leftBar} style={props.leftPanel ? {backgroundColor: props.leftPanelColor} : {}}>
                <div>
                    <img src={country.logo} className={classes.logo}></img>
                </div>

                <div className={classes.information}>
                    { props.leftPanel &&
                        <>
                            {props.leftPanel}
                        </>
                    }

                    { (client && !props.leftPanel) &&
                        <>
                            <Text className={classes.label}>{t('sidebar.company')}</Text>
                            <Text className={classes.informationText}>{client.name}</Text>
                            <Text className={classes.label}>{t('sidebar.taxId')}</Text>
                            <Text className={classes.informationText}>{client.vatNumber}</Text>

                            {
                                client.bankAccount &&
                                <>
                                    <Text className={classes.label}>{t('sidebar.accountNumber')}</Text>
                                    <Text className={classes.informationText}>BANK PEKAO SA</Text>
                                    <Text className={classes.informationText}>PL{client.bankAccount}</Text>
                                    <Text className={classes.informationText}>SWIFT/BIC: PKOPPLPW</Text>
                                </>
                            }

                            { country.code === 'es' &&
                                <>
                                    <Text className={classes.label}>Contacto:</Text>
                                    <Text className={classes.informationText}>Miguel</Text>
                                    <Text className={classes.informationText}>es@gowork.com</Text>
                                    <Text className={classes.informationText}>ul. Wirażowa 124 A, 02-145 Warszawa</Text>
                                </>
                            }
                            {
                                (client.supervisor && country.code !== 'es') &&
                                <>
                                    <Text className={classes.label}>{t('sidebar.supervisor')}</Text>
                                    <Text className={classes.informationText}>{client.supervisor.name.name} {client.supervisor.name.surname}</Text>
                                    <Text className={classes.informationText}>{client.supervisor.email}</Text>
                                    <Text className={classes.informationText}>{client.supervisor.phone}</Text>
                                    <Text className={classes.informationText}>ul. Wirażowa 124 A, 02-145 Warszawa</Text>
                                </>
                            }

                        </>
                    }
                </div>
            </div>
            <div className={classNames(classes.rightBar, {[classes.rightBarSmallPaddings]: props.smallPaddings})}>
                {props.children}
            </div>
        </div>
    )
}

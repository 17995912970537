export default function (name: string, base64: string) {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const downloadFromUrl = (name: string, url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    link.click();
}

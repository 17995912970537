import {Outlet, useParams, useSearchParams} from "react-router-dom";
import {useCountryStore} from "../store/CountryStore";
import {useEffect, useState} from "react";
import {isAvailable} from "../config/countries";
import Page404 from "./404";
import {useUserStore} from "../store/UserStore";
import MantineDefault from "../components/MantineDefault";

export default function Initial() {
    const {country: urlCountry} = useParams();
    const [params] = useSearchParams();

    const {country, changeCountry} = useCountryStore();
    const {token, changeToken} = useUserStore();

    useEffect(() => changeCountry(urlCountry || ''), [urlCountry]);
    useEffect(() => {
        if (params.get('token') && country) {
            changeToken(params.get('token') || '')
        }
    }, [params, country]);

    return (
        <MantineDefault>
            {(country && token) &&
                <Outlet/>
            }

            {
                (!isAvailable(urlCountry || '') || !token) &&
                <Page404/>
            }
        </MantineDefault>
    )
}
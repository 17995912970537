import LogoEs from "../assets/images/logo_es.png";
import {Company, PaymentOperator} from "../api/Order/Response/Order";

export interface CountryConfig {
    apiUrl: string,
    code: string,
    logo: string,
    rulesUrl: string,
    companyUrl: (company: Company) => string,
    defaultPaymentMethod: PaymentOperator,
    stripeDisabled: boolean,
}

export const GOWHISTLE_THANK_YOU = 'https://gowhistle.com/potwierdzenie-zamowienia/';

export const countries: CountryConfig[] = [
    {
        apiUrl: 'https://pl-crm-api.gowork.com',
        code: 'pl',
        logo: LogoEs,
        rulesUrl: 'https://www.gowork.pl/regulamin',
        companyUrl: company => `https://www.gowork.pl/opinie_czytaj,${company.externalId}`,
        defaultPaymentMethod: 'sap',
        stripeDisabled: true,
    },
    {
        apiUrl: 'https://es-crm-api.gowork.com',
        code: 'es',
        logo: LogoEs,
        rulesUrl: 'https://es.gowork.com/pages/rules',
        companyUrl: company => `https://es.gowork.com/${company.externalSlug}`,
        defaultPaymentMethod: 'stripe',
        stripeDisabled: false,
    },
    {
        apiUrl: 'https://fr-crm-api.gowork.com',
        code: 'fr',
        logo: LogoEs,
        rulesUrl: 'https://gowork.fr/pages/rules',
        companyUrl: company => `https://gowork.fr/${company.externalSlug}`,
        defaultPaymentMethod: 'stripe',
        stripeDisabled: false,
    },
    {
        apiUrl: 'https://de-crm-api.gowork.com',
        code: 'de',
        logo: LogoEs,
        rulesUrl: 'https://gowork.de/pages/rules',
        companyUrl: company => `https://gowork.de/${company.externalSlug}`,
        defaultPaymentMethod: 'stripe',
        stripeDisabled: false,
    },
    // for local dev
    {
        apiUrl: 'http://127.0.0.1:1533',
        code: 'gb',
        logo: LogoEs,
        rulesUrl: 'https://www.gowork.pl/regulamin',
        companyUrl: company => `https://gowork.de/${company.externalSlug}`,
        defaultPaymentMethod: 'sap',
        stripeDisabled: false,
    },
]

export const isAvailable = (code: string) => {
    return !!countries.find(country => country.code === code);
}

export const loadConfig = (code: string) => {
    return countries.find(country => country.code === code);
}
